export default (gicleeSizes, rules, edit = false) => {
  return {
    // productTypeVariantCode: {
    //   type: "select",
    //   label: "Available Product Variants",
    //   items: variants,
    //   itemText: "description",
    //   itemValue: "productTypeVariantCode",
    //   col: 6,
    //   rules: [rules.required],
    //   dense: true,
    //   outlined: true,
    //   hidden: edit
    // },
    gicleeSize: {
      type: "select",
      label: "Available Sizes",
      items: gicleeSizes,
      itemText: "title",
      col: 6,
      rules: [rules.required],
      dense: true,
      outlined: true,
      hidden: edit,
      returnObject: true
    },
    // gicleePrice: {
    //   type: "number",
    //   step: 0.01,
    //   label: "Unit Price",
    //   col: 3,
    //   disabled: true,
    //   dense: true,
    //   outlined: true,
    // },
    gicleeWidth: {
      type: "number",
      label: "Width",
      col: 3,
      dense: true,
      disabled: true,
      outlined: true,
      rules: [rules.required],
    },
    gicleeHeight: {
      type: "number",
      label: "Height",
      col: 3,
      dense: true,
      disabled: true,
      outlined: true,
      rules: [rules.required],
    },
    gicleeUnits: {
      type: "select",
      items: ["mm", "in"],
      label: "Units",
      col: 3,
      dense: true,
      outlined: true,
      rules: [rules.required],
    },
    image: {
      type: "file",
      label: "Select Product Image(s)",
      dense: true,
      outlined: true,
      id: "artworks",
      name: "artworks",
      showSize: true,
      accept: "image/*",
      col: 6,
      appendIcon: "mdi-camera",
      prependIcon: null,
      multiple: true,
      ref: "image",
    },
  };
}
