<template>
  <v-dialog v-model="value" persistent scrollable max-width="70vw">
    <v-card >
      <v-toolbar dark color="primary">
        <v-toolbar-title>Add New Product for {{artwork.artworkCode}} {{artwork.title}} by {{artwork.artist.name}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text v-if="status ==='SAVED'" class="pt-4">
        <saved-product :savedProduct="savedProduct"></saved-product>
      </v-card-text>
      <v-card-text v-else class="pt-4">
        <v-select outlined dense v-model="product.productTypeCode" label="Product Type" item-text="title" item-value="productTypeCode" :items="this.productTypes" @change="getSchema"></v-select>
        <v-autocomplete
          v-model="exclusiveTo"
          :items="customers"
          outlined
          clearable
          clear-icon="mdi-close-box"
          dense
          multiple
          item-text="customerName"
          item-value="accountNumber"
          label="Exclusive To"
          :loading="customersLoading"
        >
        </v-autocomplete>
        <v-form v-if="product.productTypeCode && productTypeVariantsFiltered.length" ref="formbase" v-model="formValid" lazy-validation @submit.prevent>
          <v-form-base v-if="product" :row="{ noGutters:false }" :model="product" :schema="schema" @input="handleEvent" />
        </v-form>
        <v-card-title v-if="product.productTypeCode && !productTypeVariantsFiltered.length">All product types have been created for this artwork</v-card-title>
        <draggable v-if="product.productTypeCode" :list="images" class="d-flex flex-wrap" @end="onEnd">
        <v-card  v-for="(image, index) in images" :key="image.uuid" class="pa-4 ma-2">
          <v-icon style="position: absolute; top: 0; right: 0;" @click="onRemove(index)">mdi-delete</v-icon>
          <v-img width="150" height="150" contain class="ma-2" :src="image.dataURL" ripple />
        </v-card>
        </draggable>
        <v-card-title v-if="images.length > 1"> Drag images to re-order</v-card-title>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!isDirty || !formValid || status === 'SAVED'" color="success" class="mr-4" @click.native="saveProduct()">
          Save
        </v-btn>
        <template v-if="!['GICL','GICO'].includes(product.productTypeCode)">
          <v-btn :disabled="status !== 'SAVED'" color="success" class="mr-4" @click.native="newProductVariant()">
            New Product Variant
          </v-btn>
          <v-btn :disabled="status !== 'SAVED'" color="success" class="mr-4" @click.native="newProduct()">
            New Product Type
          </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductApi from '@/api/admin/product'
import UserApi from '@/api/user'
import ArtworkApi from '@/api/admin/artwork'
import vFormBase from '@/components/vFormBase'
import draggable from 'vuedraggable'
import { resizeImage, readFileAsDataURL, dataURLtoFile } from '@/utilities/imageUtilities'
import { svgToImgDownload } from '@/utilities/imageUtilities'
import FormSchemas from '@/forms/schemas/'
import SavedProduct from './SavedProductView'
import { mapState } from 'vuex'
const server = process.env.VUE_APP_API_URL || ''
const api = `${server}/api/artwork`
let vc

export default {
  props: {
    value: Boolean,
    edit: Boolean,
    artwork: Object
  },
  components: {
    vFormBase,
    draggable,
    SavedProduct
  },
  async mounted() {
  },
  data() {
    return {
      images: [],
      exclusiveTo: [],
      customers: [],
      gicleeSizes: [],
      gicleeMountSizes: [],
      customersLoading: true,
      schema: null,
      savedProduct: null,
      status: 'NEW',
      product: {
        artworkCode: this.artwork.artworkCode,
        artistCode: this.artwork.artistCode,
        gicleeWidth: null,
        gicleeHeight: null,
        gicleeUnits: null,
        hasMountedVersion: false,
        image: []
      },
      currentArtworkProductVariants: [],
      currentArtworkProducts: [],
      // refreshSelect: 0,
      isDirty: false,
      formValid: true,
      rules: {
        required: (value) => !!value || 'Required.',
        uniqueVariantCode: (value) => {
          const check = _.filter(this.variants, {'productTypeCode': this.variant.productTypeCode, 'productTypeVariantCode': value?.toUpperCase() })
          return check.length === 0 || `Variant ${value?.toUpperCase()} is already in use for this product`
        }
      },
    }
  },
  computed: mapState({
    productTypes: (state) => state.products.productTypes,
    productTypeVariants: (state) => state.products.productTypeVariants,
    productSku() {
      return `${this.product.productTypeCode}-${this.product.artworkCode}-${this.product.productTypeVariantCode}`
    },
    productTypeVariantsFiltered() {
      if (this.product.productTypeCode && this.currentArtworkProducts) {
        const currentVariants = Object.keys(_.groupBy(this.currentArtworkProducts[this.product.productTypeCode], 'variantCode'))
        const variants = this.productTypeVariants.filter(item => {
          return !currentVariants.includes(item.productTypeVariantCode) && item.productTypeCode === this.product.productTypeCode
        })
        return variants
      } else return []
    }
  }),
  async created() {
    this.customersLoading = true
    UserApi.getUsers().then((results) => {
      this.customers = results.data
      this.customersLoading = false
    }),
    this.getArtworkProducts()
  },
  async mounted () {
    vc = this
  },
  methods: {
    getArtworkProducts() {
      ArtworkApi.getArtworkProducts(this.artwork.artworkCode).then((results) => {
        this.currentArtworkProducts = _.groupBy(results.data, 'productTypeCode')
        this.loadingData = false
      }).catch(
        err => {
          console.log('err', err)
          this.loadingData = false
        }
      )
    },
    getSchema(evt) {
      switch (evt) {
        case "GICL": {
          this.product.gicleeUnits = "mm"
          ProductApi.loadGicleeSizes('GICL').then((results) => {
            this.gicleeSizes = results.data.printSizes
            this.gicleeMountSizes = results.data.mountSizes
            this.schema = FormSchemas.productSchemaGICL(this.gicleeSizes, this.rules)
          })
          break
        }
        case "GICO": {
          this.product.gicleeUnits = "mm"
          ProductApi.loadGicleeSizes('GICO').then((results) => {
            this.gicleeSizes = results.data.printSizes
            this.gicleeMountSizes = results.data.mountSizes
            this.schema = FormSchemas.productSchemaGICO(this.gicleeSizes, this.rules)
          })
          break
        }
        default: {
          this.schema = FormSchemas.productSchema(this.productTypeVariantsFiltered, this.rules)
          break
        }
      }
    },
    barcodeDownload(sku) {
      const svg = document.querySelector(".vue-barcode-element");
      svgToImgDownload( svg, `${sku}-BARCODE.jpg` , 500, 200 )
    },
    onEnd() {
    },
    onRemove(img) {
      this.images.splice(img,1)
    },
    close() {
      this.$emit('close')
    },
    async handleEvent(evt) {
      this.isDirty = true
      this.$refs.formbase.validate()
      switch (evt.key) {
        case 'productTypeCode' : {
          this.productTypeVariantSelection = this.productTypeVariants[this.product.productTypeCode]
          break
        }
        case 'gicleeSize' : {
          this.product.gicleeWidth = this.product.gicleeSize.gicleeWidth
          this.product.gicleeHeight = this.product.gicleeSize.gicleeHeight
          const mountSizes = this.gicleeMountSizes.find((item) =>{
            return (item.gicleeWidth === this.product.gicleeWidth) &&(item.gicleeHeight === this.product.gicleeHeight)
          })
          if (mountSizes) {
            this.product.hasMountedVersion = true
            this.product.gicleeMountedWidth = mountSizes.gicleeMountedWidth
            this.product.gicleeMountedHeight = mountSizes.gicleeMountedHeight
          }
          break
        }
        case 'image' : {
          const _images = this.product.image
          for (const img in _images) {
            const b64str = await readFileAsDataURL(_images[img]);
             this.images.push({ name: `${this.productSku}-${crypto.randomUUID()}.jpg` , dataURL: b64str })
          }
          this.product.image = []
          break
        }
        default: console.log(evt)
      }
    },
    async saveProduct() {
      this.loadingData = true
      const masterSku = `${this.product.productTypeCode}-${this.product.artworkCode}`
      const sku = `${masterSku}-${this.product.productTypeVariantCode}`
      ProductApi.saveProduct({
        ...this.product,
        sku: sku,
        exclusiveTo: this.exclusiveTo.length > 0 ? this.exclusiveTo : ["0"],
        productImages: this.images.map(item => item.name),
        productImage: this.images.map(item => item.name)[0],
        artistCode: this.product.artistCode
        }).then( async (results) => {
        for (const image of this.images) {
          const _IMG1500 = await resizeImage(image.dataURL, { maxHeight: 1500, maxWidth: 1500 } );
          const _IMG300 = await resizeImage(image.dataURL, { maxHeight: 300, maxWidth: 300 } );
          const _IMG100 = await resizeImage(image.dataURL, { maxHeight: 100, maxWidth: 100 } );
          ArtworkApi.uploadArtworkImage( { path: '1500px', name: image.name, file: dataURLtoFile(_IMG1500.url) })
          ArtworkApi.uploadArtworkImage( { path: '300px', name: image.name, file: dataURLtoFile(_IMG300.url) })
          ArtworkApi.uploadArtworkImage( { path: '100px', name: image.name, file: dataURLtoFile(_IMG100.url) })
        }
        this.savedProduct = {...results.data }
        this.getArtworkProducts(),
        this.images = []
        this.loadingData = false
        this.status = 'SAVED'
      }).catch(
        err => {
          console.log('err', err)
          this.loadingData = false
        }
      )
      this.isDirty = false
    },
    newProduct() {
      this.product = { artworkCode: this.artwork.artworkCode, image: [] }
      this.status = 'NEW'
    },
    newProductVariant() {
      this.getSchema(this.artwork.artworkCode)
      this.product = { productTypeCode: this.product.productTypeCode, artworkCode: this.artwork.artworkCode, image: [] }
      this.status = 'NEW'
    },
    log(v){
      // this.variant.productTypeVariantCode && this.variant.productTypeVariantCode.toUpperCase()
      this.isDirty = true
      this.formValid = this.$refs.formbase.validate()
    },
  }
}
</script>
<style lang="scss" scoped>
::v-deep.v-dialog__content {
  align-items: start;
  margin-top: 50px
}
</style>
