<template>
  <v-container fluid>
    <div class="d-flex mb-4">
      <h2>Manage Artworks</h2>
      <v-spacer></v-spacer>
      <v-btn color="success" class="ml-4" @click.native="createArtwork()">
        New Artwork
      </v-btn>
    </div>
    <div class="d-flex mt-4" >
      <v-autocomplete
        :disabled="selectedArtworks.length > 0"
        class="mr-2"
        v-model="selectedArtist"
        :items="artists"
        item-text="name"
        item-value="artistCode"
        outlined
        clearable
        clear-icon="mdi-close-box"
        dense
        return-object
        :label="'Artist'"
      ></v-autocomplete>
      <v-text-field
        :disabled="selectedArtworks.length > 0"
        class="mr-2"
        v-model="searchText"
        label="Search"
        dense
        outlined
        clearable
      ></v-text-field>
      <v-btn color="success" @click.native="clearFilters()"> Reset </v-btn>

    </div>
    <v-card v-if="selectedArtworks.length" class="mb-4">
      <v-card-subtitle>Update selected artworks</v-card-subtitle>
      <v-card-text class="d-flex">
        <v-btn color="warning" :disabled="!selectedArtistCollection" class="mr-4" @click="setArtistCollection" >Set Collection</v-btn>
        <v-select
          v-model="selectedArtistCollection"
          :items="selectedArtist.artistCollections"
          label="Artist Collection"
          item-text="collectionName"
          outlined
          dense
          return-object
        ></v-select>
      </v-card-text>
    </v-card>
    <v-data-table
      v-if="selectedArtist || searchText"
      v-model="selectedArtworks"
      :search="searchText"
      :loading="loadingData"
      :items="filteredArtworks"
      :headers="headers"
      @click:row="editArtwork"
      :item-class="rowClass"
      :show-select="!!selectedArtist"
      hide-default-footer
      :disable-pagination="true"
      dense
    >
      <template v-slot:[`item.artworkCode`]="{ item }">
        <span style="white-space: nowrap">{{item.artworkCode}}</span>
      </template>
      <!-- <template v-slot:[`item.artistCollection`]="{ item }">
        <pre>{{item.artistCollection?.collectionName}} ({{item.artistCollection?.collectionId}})</pre>
      </template> -->
      <template v-slot:[`item.image`]="{ item }">
        <v-img
          max-height="100px"
          contain
          class="my-2"
          :src="`${imageUrl}/100px/${item.image}`"
        ></v-img>
      </template>
      <template v-slot:[`item.manageProducts`]="{ item }">
        <!-- <v-btn small color="success" class="mr-4" @click.native="editArtwork(item)">
          Marketing
        </v-btn> -->
        <v-btn small color="success" class="mr-4" @click.stop="editArtworkProducts(item)">
          Add Products
        </v-btn>
      </template>
    </v-data-table>
    <artwork-dialog
      v-if="openDialog"
      v-model="openDialog"
      :data="currentArtwork || null"
      :edit="edit"
      @close="closeDialog"
    />
    <artwork-products-dialog
      v-if="openProductsDialog"
      v-model="openProductsDialog"
      :artwork="currentArtwork"
      @close="closeProductsDialog"
    />
    <artwork-custom-product-dialog
      v-if="openCustomProductDialog"
      v-model="openCustomProductDialog"
      :artwork="currentArtwork"
      @close="closeProductsDialog"
    />
  </v-container>
</template>

<script>
import ArtworkDialog from "./components/ArtworkDialog";
import ArtworkProductsDialog from "./components/ArtworkProductsDialog";
import ArtworkCustomProductDialog from "./components/ArtworkCustomProductDialog";
import ArtworkApi from "@/api/admin/artwork";
import ArtistApi from "@/api/admin/artist";

export default {
  components: {
    ArtworkDialog,
    ArtworkProductsDialog,
    ArtworkCustomProductDialog
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_IMG_URL,
      searchText: null,
      openDialog: false,
      openProductsDialog: false,
      openCustomProductDialog: false,
      currentArtwork: null,
      artists: [],
      artworks: [],
      selectedArtistCollection: null,
      selectedArtist: null,
      selectedArtworks: [],
      headers: [
        { text: "seq", value: "skuSequence" },
        { text: "Artist", value: "artist.name" },
        { text: "Title", value: "title" },
        { text: "Code", value: "artworkCode" },
        // { text: "Pricing Type", value: "salesModel" },
        { text: "Collection", value: "artistCollection" },
        { text: "Subject Matter", value: "subjectMatter" },
        { text: "Image", value: "image" },
        { text: "", value: "manageProducts" },
      ],
      selectedFile: null,
      validData: false,
      valid: true,
      processing: false,
      progress: 0,
      uploadSuccess: false,
      edit: false,
      mode: "preview",
      loadingData: false
    };
  },
  async created() {
    this.loadingData = false;
    ArtistApi.loadArtists()
      .then((results) => {
        this.artists = results.data
      })
      .catch((err) => {
        console.log("err", err);
      });
    ArtworkApi.loadArtworks()
      .then((results) => {
        const data = results.data
        const artworks = data.filter((artwork) => {
          return artwork.artist?.status !== 'ARCHIVED'
        });
        this.artworks = [].concat(artworks)
        this.loadingData = false;
      })
      .catch((err) => {
        console.log("err", err);
        this.loadingData = false;
      });
  },
  mounted: function () {},
  computed: {
    filteredArtworks() {
      return this.selectedArtist
      ? this.artworks.filter((a) => a.artistCode === this.selectedArtist.artistCode)
      .map((item) => {
        if (item.artistCollection) {
          item.artistCollection = `${item.artistCollection?.collectionName} (${item.artistCollection?.collectionId})`
        }
        return item
      })
      : this.artworks;
    },

  },
  watch: {},
  methods: {
    rowClass(item) {
        return 'clickable';
    },
    clearFilters() {
      this.selectedArtist = null
      this.selectedArtworks = []
      this.selectedArtistCollection = null
      this.searchText = null
    },
    async setArtistCollection() {
      this.loadingData = true
      const updatedArtworks = this.selectedArtworks.map( artwork => {
        return { _id: artwork._id, artistCollection: this.selectedArtistCollection }
      })
      await ArtworkApi.updateArtworks(updatedArtworks)
      ArtworkApi.loadArtworks()
      .then((results) => {
        const data = results.data
        const artworks = data.filter((artwork) => {
          return artwork.artist?.status !== 'ARCHIVED'
        });
        this.artworks = [].concat(artworks)
        this.loadingData = false;
      })
      .catch((err) => {
        console.log("err", err);
        this.loadingData = false;
      });
    },
    createArtwork() {
      this.edit = false
      this.openDialog = true;
    },
    editArtwork(evt) {
      this.edit = true
      this.currentArtwork = {...evt};
      delete this.currentArtwork.artist
      this.openDialog = true;
    },
    async closeDialog(options) {
      if (options && options.edited) {
        this.searchText = options.artworkCode
        await ArtworkApi.loadArtworks()
        .then((results) => {
          this.artworks = results.data;
          this.filteredArtworks = [].concat(this.artworks)
        })
        .catch((err) => {
          console.log("err", err);
          this.loadingData = false;
        });
      }
      this.loadingData = false;
      this.currentArtwork = null;
      this.openDialog = false;
    },
    editArtworkProducts(evt) {
      this.currentArtwork = {...evt};
      switch (evt.salesModel) {
        case "WHOLESALE":
          this.openProductsDialog = true
          break
        case "CUSTOM":
          this.openCustomProductDialog = true
          break
        default:
          this.openProductsDialog = false;
          this.openCustomProductDialog = false

      }
    },
    closeProductsDialog() {
      // this.searchText = this.currentArtwork.artworkCode
      this.currentArtworkCode = null;
      this.openProductsDialog = false;
      this.openCustomProductDialog = false
    }
  },
};
</script>
<style lang="scss" scoped>
::v-deep .clickable {
  cursor: pointer
}
</style>
